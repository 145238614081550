
import { Component } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';
import SubModule from '@/components/SubModule.vue';
import { IMenuItem } from '@/components/Menu.vue';
import routes from './routes';
import { store } from './store';
import { EntityStoreGetters } from '@/base';
import { getFullName } from '@/modules/user';

@Component
export default class Admin extends SubModule
{
  private get activeItem(): typeof store[EntityStoreGetters.ACTIVE_ITEM]
  {
    return store[EntityStoreGetters.ACTIVE_ITEM];
  }

  protected _getRoutes(): RouteConfig[]
  {
    return routes;
  }

  protected _getDynamicMenuItems(): IMenuItem[]
  {
    const activeItem = this.activeItem;
    if (activeItem)
    {
      if (activeItem.$isCreated())
      {
        return [
          {
            title: getFullName(activeItem.$original, true),
            icon: `$vuetify.icons.edit`,
            route: `/admin/${activeItem.id}`,
          },
        ];
      }
      else
      {
        return [
          {
            title: this.$t('form.titleNew').toString(),
            icon: `$vuetify.icons.add`,
            route: `/admin/new`,
          },
        ];
      }
    }
    return [];
  }
}
